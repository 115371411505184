/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Sujan Rao Chikkela",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Sujan Rao Chikkela's Portfolio",
    type: "website",
    url: "http://sujanchikkela.com/",
  },
};

//Home Page
const greeting = {
  title: "Sujan Rao Chikkela",
  logo_name: "Sujan",
  nickname: "Software Developer",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink: "https://drive.google.com/file/d/inputresumefilelink",
  //portfolio_repository: "https://github.com/SujanChikkela/masterPortfolio",
  githubProfile: "https://github.com/sujanchikkela",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/sujanchikkela",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/sujanchikkela/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "ISO-SJSU",
    link: "https://isosjsu.com",
    fontAwesomeIcon: "fa-joomla", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:csujanrao@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/sujan",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },

  {
    name: "Instagram",
    link: "https://www.instagram.com/sujan/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    /*     {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
        "⚡ Experience of working with Computer Vision and NLP projects",
        "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    }, */
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/sujanchikkela/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/sujanchikkela",
    },
    /*     {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/sujanchikkela",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/sujanchikkela",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@sujanchikkela",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/sujanchikkela",
    }, */
  ],
};

const degrees = {
  degrees: [
    {
      title: "San Jose State University, San Jose",
      subtitle: "M.S. in Software Engineering",
      logo_path: "sjsu_logo.png",
      alt_name: "San Jose State University",
      duration: "2022 - 2023",
      descriptions: [
        "⚡ I have taken variety of courses related to Enterprise Software Technologies.",
        "⚡ Apart from this, I have also done research assistantship. As part of it, I have worked on creating new algorithms in Graph ML and Network Science.",
        "⚡ Coursework: Enterprise Software Platforms, Enterprise Distributed Systems, Data Mining, Enterprise Application Development, Software Systems Engineering, Business Intelligence Technologies, Software Engineering Processes, Web UI Design & Development.",
      ],
      website_link: "https://www.sjsu.edu/",
    },

    {
      title: "Jawaharlal Nehru Technological University, Hyderabad",
      subtitle: "B.Tech. in Computer Science and Engineering",
      logo_path: "jntuh_logo.png",
      alt_name: "JNTUH Hyderabad",
      duration: "2015 - 2019",
      descriptions: [
        "⚡ Completed Bachelor's degree in Computer Science and Engineering from Jawaharlal Nehru Technological University, Hyderabad, with a GPA of 3.5 out of 4.0.",
        "⚡ Acquired a comprehensive understanding of core computer science concepts, including C, C++, Java, Python, Data Structures & Algorithms, Operating Systems, Computer Networks, and Database Management.",
        "⚡ Acquired strong problem-solving and analytical skills, which are essential for developing efficient and optimized software solutions, and participated in various coding competitions and hackathons, showcasing coding skills and problem-solving abilities.",
        "⚡ Coursework: C, C++, Java, Python, Data Structures & Algorithms, Operating Systems, Computer Networks, Database Management Systems, Object Oriented Programming through Java, Design and Analysis of Algorithms, Computer Organization & Architecture, and Web Technologies.",
      ],
      website_link: "http://jntuh.ac.in",
    },

    {
      title: "Narayana Junior College, Hyderabad",
      subtitle: "High School in Maths, Physics, Chemistry",
      logo_path: "narayana_logo.png",
      alt_name: "Narayana Hyderabad",
      duration: "2013 - 2015",
      descriptions: [
        "⚡ Successfully completed high school education at Narayana Junior College in Hyderabad with a percentage of 96.7%, which was among the top 1% of students in the state.",
        "⚡ Completed advanced coursework and training in subjects such as calculus, mechanics, thermodynamics, and organic chemistry, demonstrating mastery of complex scientific concepts.",
        "⚡ Developed a deep understanding of key scientific concepts and analytical skills through rigorous coursework and practical training.",
      ],
      website_link: "https://www.narayanajuniorcolleges.com/",
    },

    {
      title: "Sri Chaitanya Techno School, Hyderabad",
      subtitle: "Secondary School",
      logo_path: "chaitanya_logo.png",
      alt_name: "Chaitanya Hyderabad",
      duration: "2011 - 2013",
      descriptions: [
        "⚡ Completed secondary education at Sri Chaitanya Techno School in Hyderabad, achieving a GPA of 9.8 out of 10.0.",
        "⚡ Developed a strong foundation in key subjects, including Mathematics, Science, English, and Social Studies, through a well-rounded curriculum and interactive teaching methods.",
        "⚡ Developed a deep understanding of key scientific concepts and analytical skills through rigorous coursework and practical training.",
      ],
      website_link: "https://srichaitanyaschool.net/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Microsoft Azure AZ-900",
      subtitle: "Azure Fundamentals",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://www.credly.com/badges/b5b321c6-d226-4b57-a24e-44345070b291",
      alt_name: "Azure AZ-900",
      color_code: "#8C151599",
    },
    {
      title: "Microsoft Azure AI-900",
      subtitle: "Azure AI Fundamentals",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://www.credly.com/badges/6cd6f591-82ef-4637-9202-461587f334cc",
      alt_name: "Azure AI-900",
      color_code: "#00000099",
    },
    {
      title: "Microsoft Azure AZ-500",
      subtitle: "Azure Security Engineer Associate",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://www.credly.com/badges/2cb1877b-9e23-42d6-86d7-34a748ca839b?source=linked_in_profile",
      alt_name: "Azure AZ-500",
      color_code: "#0C9D5899",
    },
    {
      title: "Microsoft Azure DP-900",
      subtitle: "Azure Data Fundamentals",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://www.credly.com/badges/b7eb1af5-f5b7-4051-bed5-f2d7ad9dba97?source=linked_in_profile",
      alt_name: "Azure DP-900",
      color_code: "#1F70C199",
    },
    {
      title: "Microsoft Azure SC-900",
      subtitle: "Security, Compliance, and Identity Fundamentals",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://www.credly.com/badges/3c53673b-a1a9-4c54-99cc-68eea7b7c053?source=linked_in_profile",
      alt_name: "Azure SC-900",
      color_code: "#D83B0199",
    },
    {
      title: "Applied Cybersecurity Essentials For Web Development",
      subtitle: "Purdue University x Infosys",
      logo_path: "ace_logo.png",
      certificate_link:
        "https://www.credential.net/profile/chikkelasujanrao127429/wallet",
      alt_name: "ACE",
      color_code: "#1F70C199",
    },
    /*      {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
      alt_name: "GCP",
      color_code: "#4285F499",
    }, */
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship, Contributions and Volunteership.",
  description:
    "As a software engineer and graduate student at San Jose State University, I have gained hands-on experience in software development, data analysis, and project management. Through my various projects and internships, I have honed my skills in problem-solving, collaboration, and critical thinking. I am passionate about leveraging technology to create innovative solutions that improve the user experience and drive business success.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Software Engineer",
          company: "Infosys",
          company_url: "https://infosys.com/",
          logo_path: "infosys_logo.png",
          duration: "Jan 2020 - Dec 2021",
          location: "Hyderabad, Telangana",
          description:
            "At Infosys Ltd, I was a Software Engineer extraordinaire who led the charge in designing and developing microservices for users. I worked hand in hand with a team of 6 to create itemized search fields and section modes that not only produced a lightning-fast response time of 200ms but also generated a whopping $100 million in annual revenue. How's that for a return on investment? But my accomplishments didn't stop there. I introduced new functionalities and RESTful APIs that upped the accuracy of response object definition by an impressive 30%. Plus, I was the go-to person for resolving 15+ defects in application dashboard, study, and exam mode sections with zero escalations or issues. And when it came to refactoring and optimizing the code before every build release to U.A Testing, I was on it like a hawk, working closely with a team of 3.",
          color: "#0879bf",
        },
        {
          title: "Software Engineering Intern",
          company: "VSB IT Solutions",
          company_url: "https://www.vsbitsolutions.com",
          logo_path: "vsb_logo.png",
          duration: "Jun 2019 - Dec 2019",
          location: "Hyderabad, Telangana",
          description:
            "As a Software Engineer Intern at VSB IT Solutions, I was a driving force behind the logistics-based web application backend. My innovative solutions and expertise in Node.js and MySQL led to a 30% increase in performance and stability, resulting in a jaw-dropping $20 million in annual revenue. I also introduced new functionalities, creating 10+ RESTful APIs that were optimized to produce a response time of less than 300ms. And let's not forget about the shipment tracking service, which I skillfully remodeled and made robust before its release by resolving over 30 existing bugs, all while keeping escalation at zero. To top it off, I presented three impressive demos for the pre-sales team and worked closely with clients to validate different scenarios, ensuring complete satisfaction.",
          color: "#9b1578",
        },
      ],
    },
    {
      title: "Academic Contributions",
      experiences: [
        {
          title: "Teaching Associate - ME-30",
          company: "San Jose State University",
          company_url: "https://www.sjsu.edu/",
          logo_path: "sjsu_spartan_logo.png",
          duration: "Jul 2022 - Present",
          location: "San Jose, California, USA",
          description:
            "Providing assistance to the professor Salman Ahsan by evaluating the progress and performance of 120+ students for the course ME-30 named 'Computer Applications using Python'. Teaching Python to a class of 25+ students in the computer lab every week. Assisting 120+ students in finding ways to succeed and adjust to the college education system. Training 25+ students in labs, coding sessions, and online workshops.   ",

          color: "#000000",
        },
        {
          title: "Instructional Student Assistant - CMPE-126",
          company: "San Jose State University",
          company_url: "https://www.sjsu.edu/",
          logo_path: "sjsu_spartan_logo.png",
          duration: "Jan 2023 - Present",
          location: "San Jose, California, USA",
          description:
            "Providing assistance to the professor Dr. Faramarz Mortezaie by evaluating the progress and performance of students for the course CMPE-126 named 'Algorithms and Data Structures Design using C++'",
          color: "#ee3c26",
        },
        {
          title: "Instructional Student Assistant - TECH-30",
          company: "San Jose State University",
          company_url: "https://www.sjsu.edu/",
          logo_path: "sjsu_spartan_logo.png",
          duration: "Jan 2023 - Present",
          location: "San Jose, California, USA",
          description:
            "Providing assistance to the professor Helen Sun by evaluating the progress and performance of students for the course TECH-30 named 'Introduction to Python'",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Vice president",
          company: "Indian Students Organization - San Jose State University",
          company_url: "https://www.isosjsu.com",
          logo_path: "isosjsu_logo.png",
          duration: "May 2022 - Present",
          location: "San Jose, California, USA",
          description:
            "Managing and organizing multiple cultural and festive events for 1000+ students belonging to the Indian Community at San Jose State University. Leading organization meetings, ensuring that meeting procedure is being followed and that all items are covered timely manner, and developing connections with campus departments and administrators once a week. Managing a team of 15+ Student Advisors and collaborating with different communities to guide and providing academic support and academic counseling to incoming and current students. Assisting in conducting events to help new as well as currently enrolled students with jobs and internships. ",
          color: "#4285F4",
        },

        {
          title: "Student Volunteer",
          company: "TechCrunch Disrupt",
          company_url: "https://www.techcrunch.com/",
          logo_path: "techcrunch_logo.png",
          duration: "Oct 2022 - Oct 2022",
          location: "San Francisco, California, USA",
          description:
            "Contributed as a volunteer at TechCrunch Disrupt, one of the most prominent tech conferences in the world, by assisting with registration, directions, and general inquiries, earning positive feedback from event participants. Assisted in managing and organizing event logistics, including registration and attendee inquiries, resulting in a smooth and seamless experience for over 2,000 attendees. Provided on-site support for 15+ speaker sessions, ensuring smooth transitions between presenters and maintaining timely schedules. ",
          color: "#0C9D58",
        },

        {
          title: "Campus Ambassador",
          company: "Indian Institute of Technology, Hyderabad",
          company_url: "https://www.microsoft.com/",
          logo_path: "iith_logo.png",
          duration: "Aug 2016 - Sep 2016",
          location: "Hyderabad, Telangana, India",
          description:
            "Promoting the technical festival and its events among your peers and on social media platforms. Coordinating with various college clubs and organizations to increase participation in the event. Providing feedback and suggestions to the festival organizers for improving the event. Actively participating in post-event activities, including follow-ups and feedback sessions ",
          color: "#D83B01",
        },
        {
          title: "Campus Ambassador",
          company: "Birla Institute of Technology and Science, Pilani",
          company_url: "https://www.bits-pilani.ac.in/",
          logo_path: "bits_logo.png",
          duration: "Aug 2016 - Sep 2016",
          location: "Hyderabad, Telangana, India",
          description:
            "Communicated and pitched clients for sponsorships to aid institute events. Promoted their events through digital marketing. Conducting market research and gathering feedback to identify areas for improvement for future festivals. Mentoring and training other campus ambassadors to ensure they are equipped to promote the festival and its events. ",
          color: "#000000",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    /*     {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },

    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    }, */
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_logo.svg",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://medium.com/@sujanchikkela",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "San Jose, California, USA, 95126",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/Ect3cyQkvsW6xtgu5",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
