import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import ExperienceAccordion from "../../containers/experienceAccordion/ExperienceAccordion.js";
import "./Experience.css";
import { experience } from "../../portfolio.js";
import { Fade } from "react-reveal";
import ExperienceImg from "./ExperienceImg";

// const experience = {
// 	title: "Experience",
// 	subtitle: "Work, Internship and Volunteership",
// 	description:
// 		"I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
// 	header_image_path: "experience.svg",
// 	sections: [
// 		{
// 			title: "Work",
// 			experiences: [
// 				{
// 					title: "Associate AI Engineer",
// 					company: "aaa",
// 					company_url: "aaaa",
// 					logo_path: "ogo.png",
// 					duration: "June 2020 - PRESENT",
// 					location: "Hyderabad, Telangana",
// 					description: " My goal is to make AI system which scales and removes doctor dependency as much as possible.",
// 					color: "#0879bf"
// 				},
// 				{
// 					title: "Android and ML Developer",
// 					company: "Incorporation",
// 					company_url: "https://www.linkedin.com/",
// 					logo_path: "muffito_logo.png",
// 					duration: "May 2018 - Oct 2018",
// 					location: "Hyderabad, Telangana",
// 					description:
// 						"I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
// 					color: "#9b1578"
// 				},
// 				{
// 					title: "Android Developer",
// 					company: "VSB IT GlobalPvt. Ltd.",
// 					company_url: "https://www.linkedin.com/",
// 					logo_path: "freecopy_logo.png",
// 					duration: "Nov 2017 - Dec 2017",
// 					location: "Hyderabad, Telangana",
// 					description:
// 						"I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
// 					color: "#fc1f20"
// 				}
// 			]
// 		},
// 		{
// 			title: "Internships",
// 			experiences: [
// 				{
// 					title: "Data Science Research Intern",
// 					company: "Coign Technologies Ltd.",
// 					company_url: "https://www.sujanchikkela.com/",
// 					logo_path: "logo.png",
// 					duration: "May 2019 - Sept 2019",
// 					location: "Hyderabad, Telangana",
// 					description:
// 						"I have worked on project of predicting freight rates based on previous data. I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
// 					color: "#ee3c26"
// 				},
// 				{
// 					title: "Data Science Intern",
// 					company: "KrazyBee",
// 					company_url:
// 						"https://sujanchikkela.com",
// 					logo_path: "logo.jpg",
// 					duration: "Nov 2018 - Dec 2018",
// 					location: "Work From Home",
// 					description:
// 						"This is financial Solution Company.",
// 					color: "#0071C5"
// 				}
// 			]
// 		},
// 		{
// 			title: "Volunteerships",
// 			experiences: [
// 				{
// 					title: "Google Facilitator",
// 					company: "Google",
// 					company_url: "https://about.google/",
// 					logo_path: "google_logo.png",
// 					duration: "June 2019 - April 2020",
// 					location: "Hyderabad, Telangana",
// 					description:
// 						" The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
// 					color: "#4285F4"
// 				},
// 				{
// 					title: "Microsoft Student Partner",
// 					company: "Microsoft",
// 					company_url: "https://www.microsoft.com/",
// 					logo_path: "microsoft_logo.png",
// 					duration: "Aug 2019 - May 2020",
// 					location: "Hyderabad, Telangana",
// 					description:
// 						"Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
// 					color: "#D83B01"
// 				}
// 			]
// 		}
// 	]
// };

class Experience extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="experience-main">
        <Header theme={theme} />
        <div className="basic-experience">
          <Fade bottom duration={2000} distance="40px">
            <div className="experience-heading-div">
              <div className="experience-heading-img-div">
                {/* <img
									src={require(`../../assests/images/${experience["header_image_path"]}`)}
									alt=""
								/> */}
                <ExperienceImg theme={theme} />
              </div>
              <div className="experience-heading-text-div">
                <h1
                  className="experience-heading-text"
                  style={{ color: theme.text }}
                >
                  {experience.title}
                </h1>
                <h3
                  className="experience-heading-sub-text"
                  style={{ color: theme.text }}
                >
                  {experience["subtitle"]}
                </h3>
                <p
                  className="experience-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {experience["description"]}
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <ExperienceAccordion sections={experience["sections"]} theme={theme} />
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Experience;
